<template>
  <!-- 工单管理-非居民用户 -->
  <div>
    <div class="content">
      <div class="all" v-if="!dialogVisible">
        <div class="table">
          <!--搜索框-->
          <el-form
            ref="search"
            :inline="true"
            :model="search"
            size="small"
            label-width="80px"
          >
            <el-form-item label="用户分类">
              <!--  @change="loadList(1)" -->
              <el-select v-model="search.userType" multiple collapse-tags clearable placeholder="请选择" filterable>
                <el-option
                  v-for="item in userTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务编号">
              <!--  @change="loadList(1)" -->
              <el-select v-model="search.taskCode" multiple collapse-tags clearable placeholder="请选择" filterable>
                <el-option
                  v-for="item in serial"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="片区">
              <!--  @change="loadList(1)" -->
              <el-select v-model="search.addrId" clearable multiple collapse-tags placeholder="请选择" filterable>
                <el-option
                  v-for="item in taskAddrdata"
                  :key="item.addrId"
                  :label="item.addrName"
                  :value="item.addrId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检员">
              <!--  @change="loadList(1)" -->
              <el-select v-model="search.userId" clearable multiple collapse-tags placeholder="请选择" filterable>
                <el-option
                  v-for="item in taskInspectorData"
                  :key="item.userId"
                  :label="item.inspectorName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检结果">
              <!--  @change="loadList(1)" -->
              <el-select v-model="search.safetyResults" clearable multiple collapse-tags placeholder="请选择" filterable>
                <el-option
                  v-for="item in checkerList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工单状态">
               <!-- @change="loadList(1)" -->
              <el-select v-model="search.statusFlag" clearable placeholder="请选择" filterable>
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采集模板">
              <!--  @change="loadList(1)" -->
              <el-select v-model="search.tplId" clearable placeholder="请选择" filterable>
                <el-option
                  v-for="item in taskTplIdData"
                  :key="item.tplId"
                  :label="item.tplName"
                  :value="item.tplId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="检查时间">
              <el-date-picker
                  style="width: 230px"
                  v-model="times"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  >
                  <!--  @change="loadList(1)" -->
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-input placeholder="请输入关键字" v-model="search.keyWordValue">
                  <el-select placeholder="请选择" v-model="search.keyWord" slot="prepend" filterable>
                      <el-option v-for="item in searchBar" :key="item.id" :label="item.label" :value="item.id"></el-option>
                  </el-select>
              </el-input>
            </el-form-item>
            
            <el-form-item label-width="20px">
              <div>
                <el-button type="primary" size="small" @click="loadList"
                  >查询</el-button
                >
                <el-button type="warning"  size="small" @click="reset"
                  >重置</el-button
                >
                <!-- <el-button size="small" type="primary" @click="exportFile"
                           v-if="$anthButtons.getButtonAuth('gdanguanlidaochu')"
                  >导出</el-button
                > -->
                <el-popover
                  placement="right"
                  width="200"
                  trigger="click"
                  v-model="exportVisible"
                  @after-leave="excelName = ''">
                  <el-input
                    size="mini"
                    placeholder="请输入导出文件名"
                    v-model="excelName"
                    class="exportInput">
                  </el-input>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="exportVisible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="exportFile">确定</el-button>
                  </div>
                  <el-button slot="reference"
                    size="small" type="primary"
                    style="margin-left:10px"
                    v-if="$anthButtons.getButtonAuth('gdanguanlidaochu')">
                    导出
                  </el-button>
                </el-popover>
              </div>
            </el-form-item>
          </el-form>
       
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;"
            border
            height="calc(100vh - 380px)"
            stripe
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign:'left',
              background: '#f8f8f9',
            }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="dataCode"
              label="工单编号"
            >
              <!-- <template slot-scope="{row}"> -->
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.dataCode">
                  <div  slot="reference" class="twoLines">
                    {{row.dataCode}}
                  </div>
                </el-popover> -->
                <!-- <el-tooltip class="item" effect="dark" :content="row.dataCode" placement="top-start">
                  <div class="twoLines">{{row.dataCode}}</div>
                </el-tooltip>
              </template> -->
            </el-table-column>
            <el-table-column
              prop="taskCode"
              label="任务编号"
              width="120"
            >
              <!-- <template slot-scope="{row}"> -->
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.taskCode">
                  <div  slot="reference" class="twoLines">
                    {{row.taskCode}}
                  </div>
                </el-popover> -->
                <!-- <el-tooltip class="item" effect="dark" :content="row.taskCode" placement="top-start">
                  <div class="twoLines">{{row.taskCode}}</div>
                </el-tooltip>
              </template> -->
            </el-table-column>
            <el-table-column
              prop="userName"
              label="用户名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contact"
              label="联系人"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="userPhone"
              label="电话"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="userType"
              label="用户分类"
              show-overflow-tooltip
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="addrName"
              label="片区"
            >
              <template slot-scope="{row}">
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.addrName">
                  <div  slot="reference" class="twoLines">
                    {{row.addrName}}
                  </div>
                </el-popover> -->
                <el-tooltip class="item" effect="dark" :content="row.addrName" placement="top-start">
                  <div class="twoLines">{{row.addrName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="地址"
            >
              <template slot-scope="{row}">
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.address">
                  <div  slot="reference" class="twoLines">
                    {{row.address}}
                  </div>
                </el-popover> -->
                <el-tooltip class="item" effect="dark" :content="row.address" placement="top-start">
                  <div class="twoLines">{{row.address}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="负责部门"
              show-overflow-tooltip
              width="100"
            >
            </el-table-column>
            <el-table-column prop="inspectorName" label="安检员"  width="120">
              <template slot-scope="{row}">
                <!-- <p>
                  {{fillterName(row.inspectorName)[0]}}
                </p>
                <p>
                  ({{fillterName(row.inspectorName)[1]}}
                </p> -->
                <!-- <el-tooltip class="item" effect="dark" :content="row.inspectorName" placement="top-start"> -->
                  <div class="twoLines">
                    <p>
                      {{fillterName(row.inspectorName)[0]}}
                    <br/>
                      ({{fillterName(row.inspectorName)[1]}}
                    </p>
                  </div>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column prop="safetyResultsName" label="安检结果"  width="80"></el-table-column>
            <el-table-column prop="statusFlagName" label="工单状态"  width="80">
              <template slot-scope="{row}">
                <el-tag :type="row.statusFlagName == '已完成' ? 'success' : 'danger'">{{row.statusFlagName}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="checkTime"
              label="检查时间"
            ></el-table-column>
            <el-table-column
              prop="inspectionTime"
              label="检查时长"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left">
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="addItem(3, row)"
                           v-if="$anthButtons.getButtonAuth('gdfjmck')"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
                <el-pagination
                :current-page="search.current"
                :background="true"
                :page-sizes="[10, 50, 100]"
                :page-size="search.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
      </div>
      <div v-show="dialogVisible" style="height: 100%">
        <unDetail></unDetail>
      </div>
       </div>
    </div>
    <el-dialog
      title="导出中..."
      :visible.sync="editConductVisible"
      width="30%">
      <el-table v-loading="loading" empty-text='导出中'>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import mixins from '../earlier/mixin/mixins.js'
import {getworkData,taskCodeList,taskAddrList,taskInspectorList,taskTplIdList,dataListExcel} from "@/RequestPort/workorder/maintenRecord";
import {userTypeDropDown,} from "@/RequestPort/induction";
import unDetail from './component/details';
import { exportMethod } from "../../common/js/exportExcel";
import Vue from 'vue';
export default {
  components: {unDetail},
  mixins:[mixins],
  data() {
    return {
        search: {
          current:1,
          size: 100,
          keyWord:5
        },
        total:0,
        dialogVisible: false,
        // 列表数据
        tableData:[],
        // 用户分类
        userTypes: [],
        // 任务编号
        serial:[],
        // 片区下拉
        taskAddrdata:[],
        // 安检员下拉
        taskInspectorData:[],
        // 安检结果
        checkerList: [
          { label: "正常安检", id: 1 },
          { label: "到访不遇", id: 2 },
          { label: "拒绝安检", id: 3 },
          { label: "停用", id: 4  },
        ],
        // 工单状态
        statusList: [
          { label: "已完成", id: 1 },
          { label: "未完成", id: 2 },
        ],
        // 采集模板下拉
        taskTplIdData:[],
        // 关键字条件
        searchBar:[
          { label: "用户名称", id: 1 },
          { label: "联系人", id: 2 },
          { label: "电话", id: 3 },
          { label: "地址", id: 4 },
          { label: "工单编号", id: 5 },
        ],
        // 时间
        times:[],
        // 导出弹窗
        editConductVisible:false,
        loading: true,
        exportVisible: false,
        excelName: ''
    }
  },
  mounted(){
    this.getTimeFn()
    if(this.$route.query.taskCode){
      this.search.taskCode = this.$route.query.taskCode
    }
    // 获取列表数据
    this.loadList()
    // 任务编号下拉
    this.gettaskCodeList()
    // 用户分类
    this.getuserTypeDropDown()
    // 片区下拉
    this.gettaskAddrList()
    // 安检员下拉
    this.gettaskInspectorList()
    // 采集模板下拉
    this.gettaskTplIdList()
  },
  methods: {
    //设置默认时间
			getTimeFn() {
				const that = this;
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 6); //15天把7改掉
				this.times = [that.formatDate(start) + ' 00:00:00',that.formatDate(end) + ' 23:59:59']
			},
    fillterName(name){
      return name.split('(')
    },
    close(val) {
      this.dialogVisible = !this.dialogVisible; //监听值发生变化就关闭它
    },
    /**
     * 导出
     */
    exportFile(){
      if(!this.search.tplId){
        this.$message.error('请选择采集模板');
        return
      }
      this.exportVisible = false
      // this.editConductVisible = true
      this.$message.success('正在下载中，完成后会有消息提醒，请稍等')
      let searchObj = {}
      for(let key in this.search) {
        if (key != 'size' && key != 'current' && key != 'keyWord' &&
          key != 'statusFlag' && key != 'tplId' && key != 'startTime'
          && key != 'endTime') {
          searchObj[key] = this.search[key] && this.search[key].length ? this.search[key].toString() : '';
          // console.log(String(this.search[key]))
        } else {
          searchObj[key] = this.search[key]
        }
      }
      let obj = {excelName: this.excelName,...searchObj}
      dataListExcel(obj).then(res=>{
        // exportMethod(res,'非居民工单')
        // this.editConductVisible = false
        // Vue.$loading.close()
      })
    },
    /**
     * 取消导出
     */
    cancel(){
      this.editConductVisible = false
      window.stop()
    },
    /**
     * 重置
     */
    reset(){
      this.search = {
          current:1,
          size: 100,
          keyWord:5
      }
      this.times = []
      this.loadList()
    },
    /**
     * 采集模板下拉
     */
    gettaskTplIdList(){
      taskTplIdList().then(res => {
        this.taskTplIdData = res.data
      })
    },
    /**
     * 安检员下拉
     */
    gettaskInspectorList(){
      taskInspectorList().then(res => {
        this.taskInspectorData = res.data
      })
    },
    /**
     * 片区下拉
     */
    gettaskAddrList(){
      taskAddrList().then(res => {
        this.taskAddrdata = res.data
      })
    },
    /***
     * 用户分类
     */
    getuserTypeDropDown(){
      userTypeDropDown().then(res => {
        this.userTypes = res.data
      })
    },
    /**
     * 任务编号下拉
     */
    gettaskCodeList(){
      taskCodeList().then(res => {
        this.serial = res.data
      })
    },
    /**
     * 更换条数
     */
    handleSizeChange(val){
      this.search.current = 1
      this.search.size = val
      this.loadList()
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val){
      this.search.current = val
      this.loadList()
    },
    /**
     * 获取列表数据
     */
    loadList(val){
      let searchObj = {}
      if(val){
        this.search.current = 1
      }
      if(this.times && this.times.length>0) {
        this.search.startTime = this.times[0]
        this.search.endTime = this.times[1]
      } else {
        this.search.startTime = ''
        this.search.endTime = ''
      }
      for(let key in this.search) {
        if (key != 'size' && key != 'current' && key != 'keyWord' &&
          key != 'statusFlag' && key != 'tplId' && key != 'startTime'
          && key != 'endTime') {
          searchObj[key] = this.search[key] && this.search[key].length ? this.search[key].toString() : '';
          // console.log(String(this.search[key]))
        } else {
          searchObj[key] = this.search[key]
        }
      }
      getworkData(searchObj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 查看页面
     */
    addItem(index,row){
        // this.$router.push({
        //   path: "/home/workorder/details",
        //   query: { id: row.dataId },
        // });
        const routeData = this.$router.resolve({
					path: '/home/workorder/details',      //跳转目标窗口的地址
					query: { id: row.dataId }
				})
				window.open(routeData.href);
    }
  }
};
</script>
 
<style scoped lang="less">
::v-deep .el-input-group__prepend{
  width: 67px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
  height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  height: calc(100vh - 150px);
}
.exportInput{
  margin: 10px 0;
}
</style>
import axios from '@/common/js/request'

// 工程登记列表
export function projectList(params){
    const projectList = axios({
        url: '/project/projectList',
        method: 'get',
        params
    })
    return projectList
}
export function projectInfoList(params){
    const projectList = axios({
        url: '/project/projectInfoList',
        method: 'get',
        params
    })
    return projectList
}
export function contractInfo(params){
    const contractInfo = axios({
        url: '/project/contractInfo',
        method: 'get',
        params
    })
    return contractInfo
}
// 工程状态
export function projectStatus(params){
    const projectStatus = axios({
        url: '/project/projectStatus',
        method: 'get',
        params
    })
    return projectStatus
}
// 提交工程登记
export function registerSubmit(data){
    const projectStatus = axios({
        url: '/project/registerSubmit',
        method: 'post',
        data
    })
    return projectStatus
}// 提交工程登记
export function saveTemplateByProject(data){
    const saveTemplateByProject = axios({
        url: '/completionTemplate/saveTemplateByProject',
        method: 'post',
        data
    })
    return saveTemplateByProject
}
// 新增工程登记
export function registerSave(data){
    const projectStatus = axios({
        url: '/project/registerSave',
        method: 'post',
        data
    })
    return projectStatus
}
// 查看工程信息
export function showProject(params){
    const projectStatus = axios({
        url: '/project/showProject',
        method: 'get',
        params
    })
    return projectStatus
}
export function templateByProjectList(params){
    const templateByProjectList = axios({
        url: '/completionTemplate/templateByProjectList',
        method: 'get',
        params
    })
    return templateByProjectList
}
// 关联合同下拉
export function showContract(params){
    const projectStatus = axios({
        url: '/project/showContract',
        method: 'get',
        params
    })
    return projectStatus
}
// 编辑验收范围
export function acceptScope(data){
    const projectStatus = axios({
        url: '/project/acceptScope',
        method: 'post',
        data
    })
    return projectStatus
}
// 编辑工程登记
export function registerUpdate(data){
    const projectStatus = axios({
        url: '/project/registerUpdate',
        method: 'post',
        data
    })
    return projectStatus
}
// 编辑关联合同下拉
export function showContractForUpdate(params){
    const projectStatus = axios({
        url: '/project/showContractForUpdate',
        method: 'get',
        params
    })
    return projectStatus
}
export function showContractFor(params){
    const projectStatus = axios({
        url: '/project/showContract',
        method: 'get',
        params
    })
    return projectStatus
}
// 获取登记信息
export function getRegister(params){
    const projectStatus = axios({
        url: '/project/getRegister',
        method: 'get',
        params
    })
    return projectStatus
}
// 待派工列表
export function waitDispatch(params){
    const projectStatus = axios({
        url: '/project/waitDispatch',
        method: 'get',
        params
    })
    return projectStatus
}
// 现场管理员下拉列表
export function getAllSiteManager(params){
    const projectStatus = axios({
        url: '/project/getAllSiteManager',
        method: 'get',
        params
    })
    return projectStatus
}
// 安装施工队下拉列表
export function getInstallTeam(params){
    const projectStatus = axios({
        url: '/project/getInstallTeam',
        method: 'get',
        params
    })
    return projectStatus
}
// 派工操作
export function dispatchSave(data){
    const projectStatus = axios({
        url: '/project/dispatchSave',
        method: 'post',
        data
    })
    return projectStatus
}
// 派工记录列表
export function dispatchList(params){
    const projectStatus = axios({
        url: '/project/dispatchList',
        method: 'get',
        params
    })
    return projectStatus
}
// 土方施工队下拉列表
export function getEarthworkTeam(params){
    const projectStatus = axios({
        url: '/project/getEarthworkTeam',
        method: 'get',
        params
    })
    return projectStatus
}
// 定向钻施工队下拉列表
export function getDrillingTeam(params){
    const projectStatus = axios({
        url: '/project/getDrillingTeam',
        method: 'get',
        params
    })
    return projectStatus
}
// 定向钻施工队下拉列表
export function dispatchProjectInfo(params){
    const projectStatus = axios({
        url: '/project/dispatchProjectInfo',
        method: 'get',
        params
    })
    return projectStatus
}
// 派工编辑 土方定向钻
export function dispatchUpdate(data){
    const projectStatus = axios({
        url: '/project/dispatchUpdate',
        method: 'post',
        data
    })
    return projectStatus
}
// 分页查询工程手续
export function list(params){
    const projectStatus = axios({
        url: '/formalities/list',
        method: 'get',
        params
    })
    return projectStatus
}
// 查看工程手续
export function show(params){
    const projectStatus = axios({
        url: '/formalities/show',
        method: 'get',
        params
    })
    return projectStatus
}
// 查看工程手续
export function update(data){
    const projectStatus = axios({
        url: '/formalities/update',
        method: 'post',
        data
    })
    return projectStatus
}
// 工程下拉列表
export function getProjectList(params){
    const projectStatus = axios({
        url: '/formalities/getProjectList',
        method: 'get',
        params
    })
    return projectStatus
}
export function dataInquiryExamine(params){
    const dataInquiryExamine = axios({
        url: '/completionTemplate/dataInquiryExamine',
        method: 'get',
        params
    })
    return dataInquiryExamine
}
// 新增工程手续
export function save(data){
    const projectStatus = axios({
        url: '/formalities/save',
        method: 'post',
        data
    })
    return projectStatus
}
// 删除工程手续
export function deleteList(params){
    const projectStatus = axios({
        url: '/formalities/delete',
        method: 'get',
        params
    })
    return projectStatus
}
// 删除工程手续
export function projectDetail(params){
    const projectDetail = axios({
        url: '/project/projectDetail',
        method: 'get',
        params
    })
    return projectDetail
}
// 查看停复工记录
export function projectStoppages(params){
    const projectStoppages = axios({
        url: '/project/projectStoppages',
        method: 'get',
        params
    })
    return projectStoppages
}
export function templateList(params){
    const templateList = axios({
        url: '/completionTemplate/templateList',
        method: 'get',
        params
    })
    return templateList
}
export function deleteTemplate(params){
    const deleteTemplate = axios({
        url: '/completionTemplate/deleteTemplate',
        method: 'get',
        params
    })
    return deleteTemplate
}
export function deleteSubitem(params){
    const deleteSubitem = axios({
        url: '/completionTemplate/deleteSubitem',
        method: 'get',
        params
    })
    return deleteSubitem
}
export function templateByProject(params){
    const templateByProject = axios({
        url: '/completionTemplate/templateByProject',
        method: 'get',
        params
    })
    return templateByProject
}
export function finishTemplateByProject(params){
    const finishTemplateByProject = axios({
        url: '/completionTemplate/finishTemplateByProject',
        method: 'get',
        params
    })
    return finishTemplateByProject
}
export function dataInquiryList(params){
    const dataInquiryList = axios({
        url: '/completionTemplate/dataInquiryList',
        method: 'get',
        params
    })
    return dataInquiryList
}
export function verifyList(data){
    const verifyList = axios({
        url: '/completionTemplate/verifyList',
        method: 'post',
        data
    })
    return verifyList
}
export function updateTemplate(data){
    const updateTemplate = axios({
        url: '/completionTemplate/updateTemplate',
        method: 'post',
        data
    })
    return updateTemplate
}
export function updateSubitem(data){
    const updateSubitem = axios({
        url: '/completionTemplate/updateSubitem',
        method: 'post',
        data
    })
    return updateSubitem
}
export function saveSubitem(data){
    const saveSubitem = axios({
        url: '/completionTemplate/saveSubitem',
        method: 'post',
        data
    })
    return saveSubitem
}
export function saveTemplate(data){
    const saveTemplate = axios({
        url:'/completionTemplate/saveTemplate',
        method: 'post',
        data
    })
    return saveTemplate
}
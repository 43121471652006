import {
    updateTemplate,
    deleteTemplate,
    saveTemplate,
    deleteSubitem,
    updateSubitem,
    saveSubitem,
    templateList,
    saveTemplateByProject,
    templateByProjectList,
    verifyList
} from '@/RequestPort/earlier/index'
export default {
    data(){
        return{
            dialogVisible:false,
            projectId:'',
            sourcetableData:[],
            sonTableData:[],
            pid:'',
            rowData:{},
            newObj:{},
            handleIndex:'',
            showTemplateName:''
        }
    },
    methods:{
        //格式化时间
        formatDate(date) {
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            var myweekday = date.getDate();
        
            if (mymonth < 10) {
            mymonth = "0" + mymonth;
            }
            if (myweekday < 10) {
            myweekday = "0" + myweekday;
            }
            return myyear + "-" + mymonth + "-" + myweekday;
        },
        configMenu(row){
            this.dialogVisible = true
            this.projectId = row.id
            templateList().then(res=>{
                this.sourcetableData = res.data
                this.handleIndex = 0
            })
        },
        seeDetil(row){
            this.dialogVisible = true
            this.projectId = row.id
            templateByProjectList({pid:row.id}).then(res=>{
                this.handleIndex = 0
                this.sourcetableData = res.data
            })
        },
        saveMode(){
            let obj = {
                pid:this.projectId,
                seed:this.sourcetableData
            }
            saveTemplateByProject(obj).then(res=>{
                if(res.code===200){
                    this.$message.success(res.msg)
                    this.dialogVisible = false
                    this.getList()
                }
            })
        },
        modifyMode(row){
            this.newObj = JSON.parse(JSON.stringify(row))
            this.$set(row, 'isUpdate', 2)
            this.$forceUpdate()
        },
        modifyModeson(row){
            this.newObj = JSON.parse(JSON.stringify(row))
            this.$set(row, 'isUpdate', 2)
            this.$forceUpdate()
        },
        quxiao(index, rows, row){
            if(row.id||row.seedId){ //如果是已经存在的取消.得找到之前的值，重新赋值
                row.templateSeedName = this.newObj.templateSeedName
                row.templateSeedRequired = this.newObj.templateSeedRequired
                row.templateSeedType = this.newObj.templateSeedType
                this.$set(row,'isUpdate',1)
            }else{//如果shi 通过点击新增后的取消
                if(row.templateName||row.templateSeedName){
                    row.isUpdate = 1
                }else{
                    rows.splice(index, 1);
                }
            }
        },
        deleteRow(index, rows, row) {
            if(this.activeName=='first'){
                rows.splice(index, 1);
                this.showTemplateName = ''
                this.sonTableData = []
            }else {
                if(row.id){
                    this.$confirm('是否确认移除该项？', {
                        cancelButtonText: '取消',
                        closeOnClickModal:false,
                        type:'warning',
                    }).then(() => {
                        deleteTemplate({id:row.id}).then(res=>{
                            if(res.code===200){
                                this.$message.success(res.msg)
                                rows.splice(index, 1);
                            }else {
                                this.$message.error(res.msg)
                            }
                        })
                    }).catch(()=>{})
                }else{
                    rows.splice(index, 1);
                }
            }

        },
        sondeleteRow(index, rows,row) {
            if(this.activeName=='first'){
                this.sonTableData = rows
                this.sourcetableData[this.handleIndex].templates.splice(index,1)
                this.showSon(this.sourcetableData[this.handleIndex],this.handleIndex)
                this.$forceUpdate()
            }else {
                if (row.templateSeedName) {
                    this.$confirm('是否确认移除该项？', {
                        cancelButtonText: '取消',
                        closeOnClickModal:false,
                        type: 'warning',
                    }).then(() => {
                        deleteSubitem({id: row.seedId}).then(res => {
                            if (res.code === 200) {
                                this.$message.success(res.msg)
                                rows.splice(index, 1);
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    }).catch(() => {
                    })
                } else {
                    rows.splice(index, 1);
                }
            }
        },
        saveRow(data){
            if(this.activeName=='first'){
                let data1 = JSON.parse(JSON.stringify(data))
                if(data1.id){
                    if(data1.templateDescribe==''){
                        return this.$message.error('请完善项目描述')
                    }
                    if(data1.templateName==''){
                        return this.$message.error('请完善项目名称')
                    }
                    this.$set(data, 'isUpdate', 1)
                }else{
                    if(data1.templateDescribe==''){
                        return this.$message.error('请完善项目描述')
                    }
                    if(data1.templateName==''){
                        return this.$message.error('请完善项目名称')
                    }
                    if(data1.constructionType==''){
                        return this.$message.error('请选择施工队类型')
                    }
                    this.$set(data, 'isUpdate', 1)
                    this.$forceUpdate()
                }

            }else {
                let data1 = JSON.parse(JSON.stringify(data))
                if(data1.id){
                    if(data1.templateDescribe==''){
                        return this.$message.error('请完善项目描述')
                    }
                    if(data1.templateName==''){
                        return this.$message.error('请完善项目名称')
                    }
                    let obj = {
                        constructionType: data.constructionType,
                        id: data.id,
                        templateDescribe: data.templateDescribe,
                        templateName: data.templateName,
                        templateType:2,
                        pid:this.projectId
                    }
                    updateTemplate(obj).then(res=>{
                        if(res.code===200){
                            this.$message.success(res.msg)
                            templateByProjectList({pid:this.projectId}).then(res=>{
                                this.handleIndex = 0
                                this.sourcetableData = res.data
                            })
                        }
                    })
                }else{
                    if(data1.templateDescribe==''){
                        return this.$message.error('请完善项目描述')
                    }
                    if(data1.templateName==''){
                        return this.$message.error('请完善项目名称')
                    }
                    if(data1.constructionType==''){
                        return this.$message.error('请选择施工队类型')
                    }
                    data.templateType = 2
                    data.pid = this.projectId
                    saveTemplate(data).then(res=>{
                        if(res.code===200){
                            this.$message.success(res.msg)
                            templateByProjectList({pid:this.projectId}).then(res=>{
                                this.handleIndex = 0
                                this.sourcetableData = res.data
                            })
                        }else {
                            this.$message.error(res.msg)
                        }
                    })
                    this.$set(data, 'isUpdate', 1)
                    this.$forceUpdate()
                }
            }


        },
        sonsaveRow(data){
            if(this.activeName=='first'){
                if(data.seedId){
                    if(data.templateSeedName==''){
                        return this.$message.error('请完善子项目名称')
                    }
                    data.isUpdate = 1
                    this.sourcetableData[this.handleIndex].templates = this.sonTableData
                }else{
                    if(data.templateSeedRequired==''){
                        return this.$message.error('请完善必填项')
                    }
                    if(data.templateSeedName==''){
                        return this.$message.error('请完善子项目名称')
                    }
                    if(data.templateSeedType==''){
                        return this.$message.error('请选择类型')
                    }
                    data.pid = this.pid
                    data.isUpdate = 1
                    this.sourcetableData[this.handleIndex].templates = this.sonTableData
                }
            }
            else {
                if(data.seedId){
                    if(data.templateSeedName==''){
                        return this.$message.error('请完善子项目名称')
                    }
                    let obj = {
                        id: data.seedId,
                        templateSeedRequired: data.templateSeedRequired,
                        templateSeedName: data.templateSeedName,
                        templateSeedType:data.templateSeedType
                    }
                    updateSubitem(obj).then(res=>{
                        if(res.code===200){
                            this.$message.success(res.msg)
                            this.$forceUpdate()
                        }
                    })
                    data.isUpdate = 1
                    this.sourcetableData[this.handleIndex].templates = this.sonTableData
                }
                else{
                    if(data.templateSeedRequired==''){
                        return this.$message.error('请完善必填项')
                    }
                    if(data.templateSeedName==''){
                        return this.$message.error('请完善子项目名称')
                    }
                    if(data.templateSeedType==''){
                        return this.$message.error('请选择类型')
                    }
                    data.pid = this.pid
                    saveSubitem(data).then(res=>{
                        if(res.code===200){
                            data.isUpdate = 1
                            this.$message.success(res.msg)
                        }else {
                            this.$message.error(res.msg)
                        }
                    })
                    data.pid = this.pid
                    this.sourcetableData[this.handleIndex].templates = this.sonTableData
                }
            }
        },
        onAddNewTemplate() {
            //这里点歌接口查一下，能不能添加，能添加在push,不能就直接弹窗
            verifyList({templateType:2,pid:this.projectId}).then(res=>{
                if(res.code===200){
                    let arr =[]
                    this.sourcetableData.forEach(item=>{
                        if(item.templateName){
                            arr.push(1)
                        }else {
                            arr.push(2)
                        }
                    })
                    if(arr.indexOf(2)==-1){
                        this.sourcetableData.push(
                            {
                                templateDescribe:'',
                                templateName:'',
                                constructionType:'',
                                templates:[]
                            }
                        )
                    }else {
                        this.$message.error('请完善信息后，再次添加！')
                    }
                }else {
                    // 弹窗
                    this.$confirm(res.msg, {
                        cancelButtonText: '关闭',
                        showConfirmButton:false,
                        closeOnClickModal:false,
                        type:'warning',
                    })
                }
            })
        },
        closeDialog(){
            this.showTemplateName=''
            this.sonTableData=[]
            this.pid = ''
        },
        showSon(row,index){
            this.handleIndex  = index
            row.templates.forEach(item=>{
                item.isUpdate = 1
                this.$forceUpdate()
            })
            this.showTemplateName = row.templateName
            this.pid = row.id
            this.sonTableData = JSON.parse(JSON.stringify(row.templates))
        },
        twoAddNewTemplate() {
            let arr =[]
            this.sonTableData.forEach(item=>{
                if(item.templateSeedName){
                    arr.push(1)
                }else {
                    arr.push(2)
                }
            })

            if(arr.indexOf(2)==-1){
                if(this.showTemplateName!=''){
                    this.sonTableData.push(
                        {
                            isUpdate:2,
                            templateName:'',
                            projectType:'',
                            realname:''
                        }
                    )
                }else {
                    this.$message.error('请点击要操作的一级模板')
                }
            }else {
                this.$message.error('请完善信息后，再次添加！')
            }
        },
    }
}
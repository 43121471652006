import axios from '@/common/js/request'

// 片区管理左侧树
export function findTreeList(params) {
  const findTree = axios({
    url: '/AaumAddr/findTree',
    method: 'get',
    params
  })
  return findTree
}
// 修改状态
export function userUpdateStatus(params) {
  const userUpdateStatus = axios({
    url: '/AaumArchive/userUpdateStatus',
    method: 'get',
    params
  })
  return userUpdateStatus
}
// 修改状态
export function selectInspectorsByAddr(params) {
  const selectInspectorsByAddr = axios({
    url: '/AaumArchive/selectInspectorsByAddr',
    method: 'get',
    params
  })
  return selectInspectorsByAddr
}
// 批量删除
export function userBatchDelete(data) {
  const userBatchDelete = axios({
    url: '/AaumArchive/userBatchDelete',
    method: 'post',
    data
  })
  return userBatchDelete
}// 导出
export function exportUserExcel(data) {
  const exportUserExcel = axios({
    url: '/AaumArchive/exportUserExcel',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    isloading: true,
    responseType: 'blob' //改变接收的值类型
  })
  return exportUserExcel
}// 批量删除
export function userBatchSet(data) {
  const userBatchSet = axios({
    url: '/AaumArchive/userBatchSet',
    method: 'post',
    data
  })
  return userBatchSet
}
// 全部删除
export function userAllDelete(params) {
  const userAllDelete = axios({
    url: '/AaumArchive/userAllDelete',
    method: 'get',
    params
  })
  return userAllDelete
}
//用户新增编辑
export function userInsertOrUpdate(data) {
  const userInsertOrUpdate = axios({
    url: '/AaumArchive/userInsertOrUpdate',
    method: 'post',
    data
  })
  return userInsertOrUpdate
}


// 用户分页查询
export function userPageList(params) {
  const userPageList = axios({
    url: '/AaumArchive/userPageList',
    method: 'get',
    params
  })
  return userPageList
}
// 用户分类
export function userTypeDropDown(params) {
  const userTypeDropDown = axios({
    url: '/AaumSet/userTypeDropDown',
    method: 'get',
    params
  })
  return userTypeDropDown
}
// 用户分类
export function inspectorDropDown(params) {
  const inspectorDropDown = axios({
    url: '/AaumArchive/inspectorDropDown',
    method: 'get',
    params
  })
  return inspectorDropDown
}// 用户分类
export function addrDropDown(params) {
  const addrDropDown = axios({
    url: '/AaumArchive/addrDropDown',
    method: 'get',
    params
  })
  return addrDropDown
}
// 地址联动下拉
export function areaDropDownList(params) {
  const areaDropDown = axios({
    url: '/AaumAddr/areaDropDown',
    method: 'get',
    params
  })
  return areaDropDown
}
// 片区分页查询
export function addrPageList(params) {
  const addrPageList = axios({
    url: '/AaumAddr/addrPageList',
    method: 'get',
    params
  })
  return addrPageList
}
// 部门下拉
export function deptDropDownList(params) {
  const deptDropDown = axios({
    url: '/AaumAddr/deptDropDown',
    method: 'get',
    params
  })
  return deptDropDown
}
//片区批量新增
export function addrBatchInsert(data) {
  const addrBatchInsert = axios({
    url: '/AaumAddr/addrBatchInsert',
    method: 'post',
    data
  })
  return addrBatchInsert
}
//片区批量删除
export function addrBatchDelete(data) {
  const addrBatchDelete = axios({
    url: '/AaumAddr/addrBatchDelete',
    method: 'post',
    data
  })
  return addrBatchDelete
}

// 民用用户新增或修改
export function civilUserInsertOrUpdate(data) {
  const civilUserInsertOrUpdate = axios({
    url: '/arumArchive/userInsertOrUpdate',
    method: 'post',
    data
  })
  return civilUserInsertOrUpdate
}

// 整体表具换表分页列表
export function entiretyWithReplace(params) {
  const entiretyWithReplace = axios({
    url: '/AaumArchive/entiretyWithReplace',
    method: 'get',
    params
  })
  return entiretyWithReplace
}
// 社区列表
export function arumDeptList(params) {
  const arumDeptList = axios({
    url: '/arumAddr/arumDeptList',
    method: 'get',
    params
  })
  return arumDeptList
}
// 小区批量修改社区
export function addrBatchUpdate(data) {
  const addrBatchUpdate = axios({
    url: '/arumAddr/addrBatchUpdate',
    method: 'post',
    data
  })
  return addrBatchUpdate
}
// 小区列表
export function arumAddrList(params) {
  const arumAddrList = axios({
    url: '/arumAddr/arumAddrList',
    method: 'get',
    params
  })
  return arumAddrList
}
// 楼批量修改小区
export function addrsBatchUpdate(data) {
  const addrsBatchUpdate = axios({
    url: '/arumAddr/addrsBatchUpdate',
    method: 'post',
    data
  })
  return addrsBatchUpdate
}
export function exportMethod(data,fileName) {
    const link = document.createElement('a')
    let blob = new Blob([data], {type: 'application/vnd.ms-excel'})
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    link.download = fileName //下载的文件名
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
